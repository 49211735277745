import React, {useState} from 'react';
import CountUp, {CountUpProps} from 'react-countup';
import classNames from 'classnames';

import {NotificationList, TabbedWidget} from '../..';
import {useNotificationTotals} from '../../../../hooks';

import { Props as TabbedWidgetProps } from '../../tabbedWidget/tabbedWidget.type';
import {NotificationTypes} from '../../../../types';

import * as styles from './notificationsWidget.module.scss';

type Props = {
    className?: string;
}

const NotificationsWidget: React.FC<Props> = ({ className }) => {
    const {
        isLoading,
        refresh,
        totals,
        totalNotifications,
    } = useNotificationTotals();


    const [disableCountup, setDisableCountup] = useState(false);

    const handleNotificationsUpdated = () => {
        refresh();
    }

    const countUpProps: Omit<CountUpProps, 'end'> = {
        duration: 1,
        onEnd: () => {
          setDisableCountup(true);
        },
        start: 0,
        useEasing: true,
    }

    const renderTabContents = (label: string, id: string) => {
        return (
            <>
                <span className={styles.buttonText}>{label}</span>
                <span className={styles.buttonTotal}>
                    {!disableCountup ? <CountUp {...countUpProps} end={isLoading ? 0 : totals[id]} /> : totals[id]}
                </span>
            </>
        )
    }

    const noticationTabs: TabbedWidgetProps['tabItems'] = [
        {
            id: 'actionTasks',
            label: renderTabContents('Actions', 'ActionTasks'),
            content: (
                <NotificationList
                    hideDismiss
                    onUpdate={handleNotificationsUpdated}
                    type={NotificationTypes.Action}
                    total={totals.ActionTasks}
                />
            ),
        },
        {
            id: 'reminderTasks',
            label: renderTabContents('Reminders', 'ReminderTasks'),
            content: (
                <NotificationList
                    hideAcceptDecline
                    onUpdate={handleNotificationsUpdated}
                    type={NotificationTypes.Reminder}
                    total={totals.ReminderTasks}
                />
            ),
        },
        {
            id: 'informationalTasks',
            label: renderTabContents('Info', 'InformationalTasks'),
            content: (
                <NotificationList
                    hideAcceptDecline
                    onUpdate={handleNotificationsUpdated}
                    type={NotificationTypes.Info}
                    total={totals.InformationalTasks}
                />
            ),
        }
    ]

  return (
    <TabbedWidget
        className={classNames(styles.widgetWrapper, className)}
        defaultTabId='actionTasks'
        heading={(
            <span className={styles.totalText}>
              {!disableCountup ?
                  <CountUp {...countUpProps} end={isLoading ? 0 : totalNotifications} />
                  : totalNotifications } task{totalNotifications !== 1 ? 's' : ''} require action
          </span>
        )}
        hideSpinner
        isLoading={isLoading}
        tabItems={noticationTabs}
    />
  );
};

export default NotificationsWidget;
import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'

import {
   LocationsWidget,
   NotificationsWidget,
   TotalsWidget,
   WelcomeWidget,
} from '../../../components/common/dashboard'
import { PageHeader, Seo, TabbedWidget } from '../../../components/common'
import { useActiveLocations, useProfile, useSettings } from '../../../hooks'
import { Props as TabbedWidgetProps } from '../../common/tabbedWidget/tabbedWidget.type'
import { DashboardSettingsWizard } from '../../dashboardSettingsWizard/dashboardSettingsWizard'

import * as styles from './dashboard.module.scss'

const COMPANY_NAME = process.env.APP_COMPANY

export default function Dashboard() {
   const { profile } = useProfile()
   const { settings } = useSettings()
   const { isLoading, list: locations } = useActiveLocations()

   const [isSettingsWizardOpen, setIsSettingsWizardOpen] =
      useState<boolean>(true)

   const handleCloseSettingsWizard = () => {
      setIsSettingsWizardOpen(false)
   }

   const title = `Welcome to ${COMPANY_NAME}`

   const welcomeLocationWidget: TabbedWidgetProps['tabItems'] =
      settings?.EnableLocationTracking
         ? [
              {
                 id: 'welcome',
                 label: 'Welcome',
                 content: <WelcomeWidget />,
              },
              {
                 id: 'map',
                 label: 'Map',
                 content: <LocationsWidget {...{ isLoading, locations }} />,
              },
           ]
         : [
              {
                 id: 'welcome',
                 label: 'Welcome',
                 content: <WelcomeWidget />,
              },
           ]

   const defaultTabId =
      settings?.EnableLocationTracking && locations?.length ? 'map' : 'welcome'

   return (
      <>
         <Seo title="Dashboard" />
         <PageHeader title={title} subtitle={profile.DisplayName} />

         <div className={styles.widgetContainer}>
            <Row gutterWidth={40}>
               <Col xs={12} md={12} lg={12}>
                  <TotalsWidget />
               </Col>
            </Row>
            <Row gutterWidth={40} direction="row-reverse">
               <Col xs={12} lg={6} className={styles.widgetFlexed}>
                  <NotificationsWidget className={styles.notificationsWidget} />
               </Col>
               <Col xs={12} lg={6}>
                  <TabbedWidget
                     className={styles.spacingHeading}
                     defaultTabId={defaultTabId}
                     tabItems={welcomeLocationWidget}
                  />
               </Col>
            </Row>
         </div>
         {settings?.WizardStatus === false && (
            <DashboardSettingsWizard
               isSettingsWizardOpen={isSettingsWizardOpen}
               updateIsSettingsWizardOpen={setIsSettingsWizardOpen}
               handleCloseSettingsWizard={handleCloseSettingsWizard}
            />
         )}
      </>
   )
}
